<!-- 发票详情 -->
<template>
  <div class="flex-col page">

    <div class="flex-col group_3" v-loading="loading" element-loading-text="数据正在加载中"
      element-loading-spinner="el-icon-loading">
      <div class="flex-row justify-between group_4">
        <div class="flex-row group_5">
          <span class="text_2">当前位置：发票列表</span>
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/619256350163bf0011e5cbf2/63149b9d014fb40011ef3514//16629786958662112914.png"
            class="image_6" />
          <span class="text_3">发票列表</span>
        </div>
        <div class="flex-row">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/619256350163bf0011e5cbf2/63149b9d014fb40011ef3514//16622994929819924446.png"
            class="image_7" @click="gobackBtn" />
          <span class="text_4" @click="gobackBtn">返回发票列表</span>
        </div>
      </div>
      <div class="invoice-info">
        <div class="invoice-info-cont">
          <div class="invoice-info-cont-title">发票状态：{{data.statustxt}}</div>
          <div class="invoice-info-cont-cont">
            <div class="invoice-info-cont-info">
              <div class="invoice-info-cont-infolabel">培训课程</div>
              <div class="invoice-info-cont-infotext">{{data.curriculum_name}}</div>
            </div>
            <div class="invoice-info-cont-info">
              <div class="invoice-info-cont-infolabel">开票类型</div>
             <div class="invoice-info-cont-infotext" v-if="data.invoice_id1== 1">电子发票</div>
             <div class="invoice-info-cont-infotext" v-if="data.invoice_id1== 2">专用发票</div>
            </div>
            <div class="invoice-info-cont-info">
              <div class="invoice-info-cont-infolabel">发票类型</div>
              <div class="invoice-info-cont-infotext" v-if="data.invoice_id2== 1">普通发票（个人）</div>
               <div class="invoice-info-cont-infotext" v-if="data.invoice_id2==2">普通发票（企业）</div>
               <div class="invoice-info-cont-infotext" v-if="data.invoice_id2==3">单位</div>
            </div>
            <div class="invoice-info-cont-info" v-if="data.company !=''" >
              <div class="invoice-info-cont-infolabel">单位名称</div>
              <div class="invoice-info-cont-infotext">{{data.company}}</div>
            </div>
            <div class="invoice-info-cont-info" v-if="data.taxpayer_number !=''" >
              <div class="invoice-info-cont-infolabel">纳税人识别号</div>
              <div class="invoice-info-cont-infotext">{{data.taxpayer_number}}</div>
            </div>
            <div class="invoice-info-cont-info" v-if="data.company_address !=''" >
              <div class="invoice-info-cont-infolabel">单位地址</div>
              <div class="invoice-info-cont-infotext">{{data.company_address}}</div>
            </div>
            <div class="invoice-info-cont-info" v-if="data.company_tel !=''" >
              <div class="invoice-info-cont-infolabel">单位联系电话</div>
              <div class="invoice-info-cont-infotext">{{data.company_tel}}</div>
            </div>
            <div class="invoice-info-cont-info" v-if="data.bank !=''" >
              <div class="invoice-info-cont-infolabel">开户行</div>
              <div class="invoice-info-cont-infotext">{{data.bank}}</div>
            </div>
            <div class="invoice-info-cont-info" v-if="data.bank_account !=''" >
              <div class="invoice-info-cont-infolabel">银行账号</div>
              <div class="invoice-info-cont-infotext">{{data.bank_account}}</div>
            </div>
            <div class="invoice-info-cont-info" v-if="data.name !=''" >
              <div class="invoice-info-cont-infolabel">姓名</div>
              <div class="invoice-info-cont-infotext">{{data.name}}</div>
            </div>
            <div class="invoice-info-cont-info">
              <div class="invoice-info-cont-infolabel">接收邮箱</div>
              <div class="invoice-info-cont-infotext">{{data.email}}</div>
            </div>
            <div class="invoice-info-cont-info">
              <div class="invoice-info-cont-infolabel">申请时间</div>
              <div class="invoice-info-cont-infotext">{{data.create_time}}</div>
            </div>
            <div class="invoice-info-cont-info">
              <div class="invoice-info-cont-infolabel">发票状态</div>
              <div class="invoice-info-cont-infotext">{{data.statustxt}}</div>
            </div>
          </div>
        </div>
        <div class="come-back" @click="gobackBtn()">返回</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        data: [],
        loading: false,
        identity:''
      };
    },
    created() {
      let that = this;
      that.getOrderinvoiceDetail();
    },
    methods: {
      getOrderinvoiceDetail() {
        let that = this;
        that.loading = true;
        let order_invoice_id = this.$route.query.order_invoice_id;
        let param = {
          order_invoice_id
        };
        that.$request.getOrderinvoiceDetail(param).then((res) => {
          if (res.code == 200) {
            that.data = res.datas;
            that.loading = false;
          }
        });
      },
      //返回
      gobackBtn() {
        let that = this;
        that.$router.go(-1)
      },
      //报名
      baomingBtn() {
        let that = this;
        if (that.data.registration_instructions) {
          that.$confirm(that.data.registration_instructions, '报名通知', {
            confirmButtonText: '我已知悉',
            cancelButtonText: '返回',
            type: 'warning',
            center: true
          }).then(() => {
            that.$confirm('您确定要报名当前课程吗', '报名课程', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              center: true
            }).then(() => {
              that.toBaoming();
            }).catch(() => {
              /* this.$message({
                type: 'info',
                message: '已取消删除'
              }); */
            });
          }).catch(() => {
            /* this.$message({
              type: 'info',
              message: '已取消删除'
            }); */
          });
        } else {
          that.$confirm('您确定要报名当前课程吗', '报名课程', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            center: true
          }).then(() => {
            that.toBaoming();
          }).catch(() => {
            /* this.$message({
				type: 'info',
				message: '已取消删除'
			  }); */
          });
        }

      },
      toBaoming() {
        let that = this;
        let curriculum_id = that.data.curriculum_id;

        let param = {
          curriculum_id: curriculum_id,
        };

        that.$request.getCurriculumBaoming(param).then((res) => {
          if (res.code == 200) {
            that.$myMessage.success(res.msg);
            setTimeout(function() {
              //报名成功，跳转到支付页面
              that.$router.push({
                'path': '/user/order/detail',
                query: {
                  ordersn: res.datas.ordersn
                }
              })
            }, 2000);
          }
        });
      },
    },
  };
</script>

<style scoped lang="css">
  .page {
    background-color: #f5f6fa;
    width: 89% !important;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding: 1.25rem 1.25rem 1.88rem;
  }

  .section_1 {
    padding: 0.63rem 1.25rem 0.63rem 3.13rem;
    background-color: #ffffff;
  }

  .space-x-12>*:not(:first-child) {
    margin-left: 0.75rem;
  }

  .group {
    margin: 0.13rem 0;
    overflow: hidden;
    width: 15.56rem;
    height: 2.88rem;
  }

  .image {
    flex-shrink: 0;
    width: 2.88rem;
    height: 2.88rem;
  }

  .text {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 1.5rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.41rem;
  }

  .space-x-15>*:not(:first-child) {
    margin-left: 0.94rem;
  }

  .group_1 {
    overflow: hidden;
    width: 12.88rem;
    height: 3.13rem;
  }

  .space-x-5>*:not(:first-child) {
    margin-left: 0.31rem;
  }

  .group_2 {
    overflow: hidden;
    width: 5.63rem;
    height: 3.13rem;
  }

  .image_1 {
    flex-shrink: 0;
    width: 2.5rem;
    height: 3.13rem;
  }

  .text_1 {
    align-self: center;
    color: #3d3d3d;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    font-weight: 700;
    line-height: 0.88rem;
  }

  .section_2 {
    align-self: center;
    background-color: #d8d8d8;
    width: 0.094rem;
    height: 0.81rem;
  }

  .image_2 {
    align-self: center;
    width: 1.13rem;
    height: 1.13rem;
  }

  .group_3 {
    /* padding: 0 1.25rem 4.44rem 17.19rem; */
  }

  .group_4 {
    padding: 1.25rem 1.41rem 1.25rem 1.56rem;
  }

  .group_5 {
    margin: 0.19rem 0 0.13rem;
    padding: 0.19rem 0 0.13rem;
    overflow: hidden;
    width: 12.25rem;
    height: 1.25rem;
  }

  .text_2 {
    margin-bottom: 0.13rem;
    flex-shrink: 0;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .image_6 {
    flex-shrink: 0;
    width: 0.88rem;
    height: 0.88rem;
  }

  .text_3 {
    margin-bottom: 0.13rem;
    flex-shrink: 0;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .image_7 {
    flex-shrink: 0;
    width: 1.56rem;
    height: 1.56rem;
    cursor: pointer;
  }

  .text_4 {
    margin: 0.41rem 0 0.38rem 0.28rem;
    color: #0074ff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.78rem;
    cursor: pointer;
  }

  .section_3 {
    padding: 0 1.25rem 2.63rem 1.53rem;
    background-color: #ffffff;
  }
.invoice-info{
   padding-bottom: 5rem;
background-color: #fff;
}
.invoice-info-cont{
  margin: 1.2rem;
  border: 1px solid #d8d8d8;
}
.invoice-info-cont-title{
  text-indent: 2rem;
  background-color: #0074ff;
  color: #fff;
  line-height: 3;
  font-size: 1rem;
}
.invoice-info-cont-cont{
  padding-top: 3rem;
  padding-left: 3rem;
  padding-bottom: 5rem;
  background-color: #FBFCFF;
}
.invoice-info-cont-info{
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.invoice-info-cont-infolabel{
  width: 10%;
  color: #9A9DA7;
  text-align: right;
}
.invoice-info-cont-infotext{
  text-indent: 2rem;
  color: #2F3541;
}
.come-back{
  margin-left: 1.2rem;
  display: inline-block;
  background-color: #F5F8FF;
  color: #0074ff;
  padding: 0 1rem;
  line-height: 2rem;
  border-radius: 5px;
  cursor: pointer;
}
</style>
